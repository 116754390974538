import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import FormContact from "components/Forms/FormContact";

const ContactRowForm = ({ data }) => {
  const { formType } = data;

  let contactForm = null;

  switch (formType) {
    case "Contact":
      contactForm = <FormContact />;
      break;
    default:
      contactForm = <FormContact />;
  }

  return <Wrapper mb={[4, 4, 0]}>{contactForm}</Wrapper>;
};
export default ContactRowForm;

const Wrapper = styled(Box)``;
