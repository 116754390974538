import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import Image from "next/image";
import Link from "next/link";
import slugify from "slugify";

const PageLinkRowRowItem = ({ data }) => {
  const {
    details: { tableData },
    image,
    linkUrl,
    linkPage,
  } = data || {};

  const tableItems = tableData?.flat() || [];

  let hrefLink = linkUrl;
  if (linkPage) {
    hrefLink = `/${linkPage?.slug}`;

    if (linkPage?.parent) {
      hrefLink = `/${linkPage?.parent?.slug}-${linkPage?.slug}`;
      if (linkPage?.site !== "Marquette") {
        hrefLink = `/${String(slugify(linkPage?.site)).toLowerCase()}/${
          linkPage?.parent?.slug
        }-${linkPage?.slug}`;
      }
    }
  }

  return (
    <Link href={hrefLink || "/"} passHref>
      <Wrapper
        as="a"
        py={[2, 3]}
        tableItemsLength={parseInt(tableItems?.length)}
      >
        <ImageWrapper mr={[3, 0]}>
          <Image
            alt="Page Link Row Image"
            src={`${image?.url}?q=80&w=400` || "/"}
            layout="fill"
            objectFit="cover"
          />
        </ImageWrapper>
        <TableTextItems tableItemsLength={parseInt(tableItems?.length)}>
          {tableItems?.map((data, i) => (
            <TableItem variant="pageLinkBody" key={`table-item-${i}`}>
              {data}
            </TableItem>
          ))}
        </TableTextItems>
      </Wrapper>
    </Link>
  );
};
export default PageLinkRowRowItem;

const Wrapper = styled(Box)`
  display: grid;

  grid-template-columns: ${({ tableItemsLength }) =>
      1 / tableItemsLength + "fr"} 1fr;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[4]};

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[4]};
  }

  cursor: pointer;
`;

const TableItem = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[6]};
  &:first-of-type {
    color: ${({ theme }) => theme.colors.text};
  }
`;
const ImageWrapper = styled(Box)`
  width: 141px;
  height: 104px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.imagePlaceholderBg};
`;

const TableTextItems = styled(Box)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.small} {
    display: grid;
    grid-template-columns: repeat(
      ${({ tableItemsLength }) => tableItemsLength},
      1fr
    );
  }

  width: 100%;
`;
