import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

const StackRowItem = ({ data }) => {
  const { heading, body } = data;

  const renderOption = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) =>
        node?.content?.map((text, i) => (
          <StyledBullet
            key={`styled-bullet-${i}`}
            paddingTop={["8px", "11px"]}
            paddingBottom={["8px", "12px"]}
            data-list="custom"
          >
            {documentToReactComponents(text)}
          </StyledBullet>
        )),
    },
    renderText: (text) =>
      text
        .split("\n")
        .flatMap((text, i) => [i > 0 && <br key={`br-${i}`} />, text]),
  };

  return (
    <Wrapper py={[2, 3]} pb={["32px", "58px"]}>
      <HeadingWrapper>
        <Text variant="stackRowHeading" as="h1" mb={[3, 3, 4]}>
          {heading}
        </Text>
      </HeadingWrapper>
      <HeadingBody>
        <CopyWrapper variant="stackRowBody">
          {documentToReactComponents(body?.json, renderOption)}
        </CopyWrapper>
      </HeadingBody>
    </Wrapper>
  );
};
export default StackRowItem;

const Wrapper = styled(Box)`
  display: grid;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[4]};
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mediaQueries.medium} {
    grid-template-columns: 1fr 1fr;
  }
`;
const HeadingWrapper = styled(Box)``;
const HeadingBody = styled(Box)`
  p {
    ${({ theme }) =>
      theme.mq({
        width: ["95%", "95%", "100%"],
      })};
  }
`;
const StyledBullet = styled(Box)`
  list-style: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[4]};
  display: block;
  /* 
  &:last-of-type {
    border-bottom: none;
  } */
`;
const CopyWrapper = styled(Text)`
  [data-list="custom"]:first-child {
    padding-top: 0px;
  }

  p + [data-list="custom"] {
    border-top: 1px solid ${({ theme }) => theme.colors.grey[4]};
  }
  [data-list="custom"] + p {
    margin-top: 1.5rem;
  }
  p strong,
  p b {
    font-family: "CentraNo1-Medium";
  }
  p {
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  color: ${({ theme }) => theme.colors.rows.stackRow.bodyCopy.text};
`;
