import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { AspectRatio as AspectRatioComponent, Box, Text } from "theme-ui";
import SvgPlusIcon from "components/Svg/SvgPlusIcon";
import { css } from "@emotion/react";
import Image from "next/image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import SvgMinusIcon from "components/Svg/SvgMinusIcon";

const AccordionItem = ({ data, style }) => {
  const { heading1, heading2, image, body } = data;
  const [show, setShow] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef();
  useEffect(() => {
    if (contentRef.current) {
      window.addEventListener("resize", setHeight);
    }
  }, [contentRef]);

  const setHeight = () => {
    if (contentRef?.current?.getBoundingClientRect()) {
      const { height } = contentRef?.current?.getBoundingClientRect();
      if (height < 140) {
        setContentHeight(140);
      } else {
        setContentHeight(height);
      }
    }
  };

  useEffect(() => {
    setHeight();
  }, [show]);

  const HeaderWrapper = styled(Box)`
    display: grid;
    cursor: pointer;
    position: relative;
    ${({ theme }) =>
      theme.mq({
        gridTemplateColumns: ["1fr", "1fr 1fr"],
      })};
  `;
  const BodyWrapper = styled(Box)`
    display: grid;

    grid-template-columns: 1fr;

    ${({ theme }) => theme.mediaQueries.small} {
      grid-template-columns: ${image ? "1fr 1fr" : "1fr"};
    }
  `;
  const Body = styled(Text)`
    color: ${({ theme }) =>
      show ? theme.colors.white[1] : theme.colors.grey[6]};
  `;
  return (
    <Wrapper>
      <HeaderWrapper
        py={["12px", 3]}
        onClick={() => {
          setShow((p) => !p);
        }}
      >
        <Heading variant="accordionText">{heading1}</Heading>
        <Body
          variant="accordionText"
          pr={[4, 5]}
          className="accordion-heading-body"
        >
          {heading2}
        </Body>
        <ToggleWrapper py={["12px", 3]}>
          {show ? <SvgMinusIcon /> : <SvgPlusIcon />}
        </ToggleWrapper>
      </HeaderWrapper>
      <BodyContainer contentHeight={contentHeight} show={show}>
        <BodyWrapper ref={contentRef} pb={[4, 5]} pt={[3, 4]}>
          {image && (
            <ImageContent mb={[4, 0]}>
              <AspectRatio ratio={1}>
                <ImageWrapper>
                  <Image
                    src={`${image?.url}?q=80&w=600` || "/"}
                    layout="fill"
                    objectFit="cover"
                    objectPosition={"top"}
                    alt="Accordion Media"
                  />
                </ImageWrapper>
              </AspectRatio>
            </ImageContent>
          )}
          <BodyContent variant="accordionText">
            {documentToReactComponents(body?.json)}
          </BodyContent>
        </BodyWrapper>
      </BodyContainer>
    </Wrapper>
  );
};
export default AccordionItem;

const Wrapper = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[4]};
  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[4]};
  }
  &:hover {
    .accordion-heading-body {
      color: ${({ theme }) => theme.colors.white[1]};
    }
  }
`;
const BodyContainer = styled(Box)`
  overflow: hidden;
  height: ${({ contentHeight, show }) => (show ? `${contentHeight}px` : `0px`)};
  transition: all 0.4s ease-in-out;
`;
const BodyContent = styled(Text)`
  p {
    margin-top: 1.5rem;
    &:first-of-type {
      margin-top: 0;
    }
    color: ${({ theme }) => theme.colors.grey[6]};
  }

  display: block;

  width: 95%;

  ${({ theme }) => theme.mediaQueries.medium} {
    width: 80%;
  }
`;
const Heading = styled(Text)``;
const ToggleWrapper = styled(Box)`
  position: absolute;

  ${({ theme }) =>
    theme.mq({
      width: ["14px"],
      right: ["8px", "16px"],
    })};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ImageContent = styled(Box)`
  width: 50%;
`;
const ImageWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.imagePlaceholderBg};
  position: relative;
  &,
  img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: ${({ theme }) => theme.radii.global};
  }
`;

const AspectRatio = styled(AspectRatioComponent)`
  &,
  * {
    border-radius: ${({ theme }) => theme.radii.global};
  }
`;
