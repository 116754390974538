import React from "react";
import ContentRowLayout from "components/Global/ContentRowLayout";
import PageLinkRowGrid from "./PageLinkRowGrid";
import PageLinkRowRow from "./PageLinkRowRow";
import PageLinkRowList from "./PageLinkRowList";

const PageLinkRow = ({ data }) => {
  const { label, style } = data;

  let noBorder = false;
  let borderType = "under";
  let Component = null;

  switch (style) {
    case "Grid":
      Component = <PageLinkRowGrid data={data} />;
      break;
    case "List":
      Component = <PageLinkRowList data={data} />;
      noBorder = true;
      borderType = "over";
      break;
    case "Row":
      Component = <PageLinkRowRow data={data} />;
      noBorder = true;
      borderType = "over";
      break;
    default:
      Component = null;
  }

  return (
    <ContentRowLayout
      noBorder={noBorder}
      label={label}
      fullWidth
      borderType={borderType}
    >
      {Component}
    </ContentRowLayout>
  );
};
export default PageLinkRow;
