import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import mapNumber from "utils/mapNumber";

const GalleryRowCarouselProgressBar = ({ progressSettings }) => {
  const { width, virtualSize, progress } = progressSettings;

  const progressWidth = (width / virtualSize) * 100;
  const pw = width - (width * progressWidth) / 100;

  let p = progress;

  if (p <= 0) {
    p = 0;
  } else if (p >= 1) {
    p = 1;
  }

  const progressBarLeft = mapNumber(p, 0, 1, 0, pw);

  return (
    <Wrapper mt={[4, 5]} progressSettings={progressSettings}>
      <ProgressBar
        progressWidth={progressWidth}
        progressBarLeft={progressBarLeft}
      />
    </Wrapper>
  );
};
export default GalleryRowCarouselProgressBar;

const Wrapper = styled(Box)`
  width: 100%;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.progressBar.bg};
  height: 2px;
  overflow: hidden;
  &:last-of-type {
    margin-right: 0;
  }
  cursor: pointer;
  position: relative;
`;
const ProgressBar = styled(Box)`
  left: ${({ progressBarLeft }) => `${progressBarLeft}px`};
  width: ${({ progressWidth }) => `${progressWidth}%`};
  position: absolute;
  height: 100%;
  background-color: white;
  background: ${({ theme }) => theme.colors.progressBar.progress};
`;
