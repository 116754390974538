import React from "react";
import styled from "@emotion/styled";
import { Box, Button, Container, Text } from "theme-ui";
import SvgDownloadIcon from "components/Svg/SvgDownloadIcon";
import ContentRowLayout from "components/Global/ContentRowLayout";

const DownloadRow = ({ data }) => {
  const { heading, downloadLink } = data || {};

  return (
    <Container px={[3, 4]}>
      <Wrapper padding={[3, 4]}>
        <HeadingWrapper>
          <Heading variant="medium" mb={[4, 5]}>
            {heading}
          </Heading>
          <SvgDownloadIcon />
        </HeadingWrapper>
        <Button variant="secondary">Download</Button>
      </Wrapper>
    </Container>
  );
};
export default DownloadRow;

const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white[1]};
  color: ${({ theme }) => theme.colors.grey[1]};
  border-radius: ${({ theme }) => theme.radii.global};
`;
const Heading = styled(Text)`
  display: block;
`;
const HeadingWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
