import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import PageLinkRowGridItem from "./PageLinkRowGridItem";

const PageLinkRowGrid = ({ data }) => {
  const { heading, body, pageLinkItemsCollection } = data || {};
  const pageLinkItems = pageLinkItemsCollection?.items;

  return (
    <Wrapper>
      {pageLinkItems?.map((data, key) => (
        <PageLinkRowGridItem data={data} key={`page-link-item${key}`} />
      ))}
    </Wrapper>
  );
};
export default PageLinkRowGrid;

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 2rem;
  ${({ theme }) => theme.mediaQueries.small} {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 4rem;
  }
  ${({ theme }) => theme.mediaQueries.medium} {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 4rem;
  }
`;
