import React, { useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Container } from "theme-ui";
import ContentRows from "./ContentRows";
import Seo from "components/Seo";
import { AppContext } from "contexts/AppContext";
import PageOverview from "./PageOverview";

const Page = ({ data }) => {
  const {
    seo,
    rowsCollection,
    pageCta,
    overviewHeading,
    overviewBody,
    footerLinksCollection,
    heroSlideCollection,
    isHome,
    slug,
  } = data?.pageCollection?.items[0] || {};
  const rowComponents = rowsCollection?.items;
  const heroSlides = heroSlideCollection?.items;
  const { setHeroSlides, setShowHeroSlide } = useContext(AppContext);
  useEffect(() => {
    if (isHome) {
      setHeroSlides(heroSlides);
    }
    setShowHeroSlide(isHome &&  heroSlides.length > 0);
  }, [isHome]);

  return (
    <Wrapper>
      <PageOverview
        copy={overviewBody}
        mt={["38px", "100px"]}
        mb={["18px", "32px"]}
        isHome={isHome}
      />
      <ContentRows rowComponents={rowComponents} />
      <Seo title={seo?.title} isHome={slug === "home"} />
    </Wrapper>
  );
};
export default Page;

const Wrapper = styled(Box)``;
