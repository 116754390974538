import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import ContentRowLayout from "components/Global/ContentRowLayout";
import AccordionRowComponent from "./AccordionRowComponent";

const AccordionRow = ({ data }) => {
  const { label } = data;
  return (
    <ContentRowLayout fullWidth noBorder label={label} borderType={"over"}>
      <AccordionRowComponent data={data} />
    </ContentRowLayout>
  );
};
export default AccordionRow;

const Wrapper = styled(Box)``;
