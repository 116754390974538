import React from "react";
import styled from "@emotion/styled";
import { Box, Button, Text } from "theme-ui";
import Table from "./Table";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Link from "next/link";

const TableRowCopy = ({ data, isImageExist }) => {
  const {
    heading,
    body,
    style,
    table,
    ctaButtonText,
    ctaButtonLinkUrl,
    ctaButtonLinkPage,
  } = data || {};

  const tableItems = [...table?.tableData].slice(1);

  let hrefLink = ctaButtonLinkUrl;
  if (ctaButtonLinkPage) hrefLink = `/${ctaButtonLinkPage?.slug}`;

  const Wrapper = styled(Box)`
    width: 100%;
    ${({ theme }) => theme.mediaQueries.medium} {
      width: ${isImageExist ? `80%` : `100%`};
    }
  `;
  const HeadingWrapper = styled(Box)`
    width: ${isImageExist ? `100%` : `50%`};
  `;

  return (
    <Wrapper>
      {(Boolean(heading) || Boolean(body)) && (
        <HeadingWrapper mb={[3, 4]}>
          {heading && (
            <Heading variant="copyHeading" mb={[2, 3]} as="h1">
              {heading}
            </Heading>
          )}
          <Body mb={[4, 0]} variant="copyBody">
            {documentToReactComponents(body?.json)}
          </Body>
        </HeadingWrapper>
      )}
      <Table style={String(style).toLowerCase()} tableItems={tableItems} />
      {ctaButtonText && (
        <Link href={hrefLink || "/"} passHref>
          <Button as="a" variant="downloadButton" mt={[3, 4]}>
            {ctaButtonText}
          </Button>
        </Link>
      )}
    </Wrapper>
  );
};
export default TableRowCopy;

const Heading = styled(Text)``;
const Body = styled(Text)`
  display: block;
  color: ${({ theme }) => theme.colors.grey[6]};
  ${({ theme }) =>
    theme.mq({
      width: ["100%", "95%"],
    })};
`;
