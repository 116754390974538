import React from "react";
import styled from "@emotion/styled";
import { AspectRatio, Box, Text } from "theme-ui";
import Media from "components/Media";
import Link from "next/link";

const PageLinkRowGridItem = ({ data }) => {
  const {
    image,
    linkUrl,
    linkPage,
    details: { tableData },
  } = data;

  const tableItems = tableData?.flat() || [];

  let hrefLink = linkUrl;
  if (linkPage) {
    hrefLink = `/${linkPage?.slug}`;
    if (linkPage?.parent) {
      hrefLink = `/${linkPage?.parent?.slug}-${linkPage?.slug}`;
    }
  }

  return (
    <Link href={hrefLink || "/"} passHref>
      <Wrapper as="a">
        <AspectRatio ratio={321 / 213}>
          <ImageWrapper>
            <Media
              alt="Page Link Grid Image"
              src={`${image?.url}?w=700&q=80` || "/"}
              layout="fill"
              objectFit="cover"
            />
          </ImageWrapper>
        </AspectRatio>
        <TextWrapper mt={[2, 3]} pr={[3]}>
          {tableItems?.map((data, i) => (
            <TextItem variant="pageLinkBody" key={`table-item-${i}`}>
              {data}
            </TextItem>
          ))}
        </TextWrapper>
      </Wrapper>
    </Link>
  );
};
export default PageLinkRowGridItem;

const Wrapper = styled(Box)`
  margin-right: 1px;
  cursor: pointer;
`;
const ImageWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.imagePlaceholderBg};
  position: relative;
  width: 100%;
  height: 100%;
`;
const TextWrapper = styled(Box)``;
const TextItem = styled(Text)`
  display: block;
  color: ${({ theme }) => theme.colors.grey[6]};
  &:first-of-type {
    color: ${({ theme }) => theme.colors.text};
  }
`;
