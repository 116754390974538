import React, { useState } from "react";
import styled from "@emotion/styled";
import { AspectRatio, Box } from "theme-ui";
import { SwiperSlide, Swiper } from "swiper/react";
import Media from "components/Media";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import HeroSliderPagination from "components/HeroSlide/HeroSliderPagination";

const MediaCarousel = ({
  collection,
  video,
  videoThumbnail,
  autoPlay,
  ...rest
}) => {
  const slideTime = 3500;
  let mediaSlides = collection?.items;

  if (video?.playbackId) {
    mediaSlides = [
      {
        muxId: video?.playbackId,
        thumb: videoThumbnail,
      },
    ];
  }

  const [currentIndex, setCurrentIndex] = useState(null);
  const [swiper, setSwiper] = useState(null);

  const handleProgress = (swiper) => {
    setCurrentIndex(swiper?.activeIndex);
  };

  const [firstMedia] = mediaSlides;
  const isVideo = (_ct) =>
    _ct === "video/mp4" || _ct === "application/x-mpegURL";

  if (mediaSlides.length < 1) return null;

  return (
    <HeroSlideWrapper mb={[4, 4, 0]} {...rest}>
      <Wrapper ratio={firstMedia?.width / firstMedia?.height || 16 / 9}>
        {mediaSlides?.length > 1 && (
          <ArrowWrapper>
            <ArrowRight
              onClick={() =>
                swiper.slideTo(
                  currentIndex === 0
                    ? mediaSlides.length - 1
                    : parseInt(currentIndex - 1)
                )
              }
            >
              &#8592;
            </ArrowRight>
            <ArrowRight
              onClick={() =>
                swiper.slideTo(
                  currentIndex === mediaSlides.length - 1
                    ? 0
                    : parseInt(currentIndex + 1)
                )
              }
            >
              &#8594;
            </ArrowRight>
          </ArrowWrapper>
        )}
        <InnerWrapper>
          <Swiper
            modules={[Autoplay, EffectFade]}
            effect="fade"
            onSwiper={(swiper) => {
              setSwiper(swiper);
            }}
            autoplay={{
              delay: slideTime,
              disableOnInteraction: false,
              autoplay: true,
            }}
            onActiveIndexChange={handleProgress}
            onAutoplayStart={() => setCurrentIndex(0)}
          >
            {mediaSlides?.map(({ url, contentType, fileName, muxId }, i) => (
              <SwiperSlide key={`media-slide-${i}`}>
                {isVideo(contentType) || muxId ? (
                  <Media
                    type="video"
                    src={url}
                    layout="fill"
                    muxPlaybackId={muxId}
                    objectFit="cover"
                    contentType={contentType}
                    fileName={fileName}
                    videoThumbnail={videoThumbnail}
                    videoSettings={{
                      autoPlay: autoPlay || false,
                      loop: false,
                      muted: true,
                      controls: false,
                      playsInline: true,
                    }}
                  />
                ) : (
                  <Media
                    type="img"
                    src={`${url}?w=1400&q=80`}
                    layout="fill"
                    objectFit="cover"
                    alt="Media Carousel"
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </InnerWrapper>
      </Wrapper>
      <HeroSliderPagination
        heroSlides={mediaSlides}
        slideTime={slideTime}
        swiper={swiper}
        currentIndex={currentIndex}
      />
    </HeroSlideWrapper>
  );
};
export default MediaCarousel;

const ArrowWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  cursor: pointer;

  div {
    opacity: 0;
    transition: all 250ms ease-in-out;
  }

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

const Arrow = styled(Box)`
  ${({ theme }) =>
    theme.mq({
      height: ["30px", "40px"],
      width: ["30px", "40px"],
      fontSize: ["16px", "20px"],
    })};

  cursor: pointer;

  user-select: none;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  color: white;
`;
const ArrowRight = styled(Arrow)``;

const Wrapper = styled(AspectRatio)`
  .swiper {
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.radii.global};
  }
  .swiper-slide-visible > div {
    display: block;
  }
  border-radius: ${({ theme }) => theme.radii.global};
  background-color: ${({ theme }) => theme.colors.imagePlaceholderBg};
`;

const InnerWrapper = styled(Box)`
  width: 100%;
  height: 100%;
`;

const HeroSlideWrapper = styled(Box)``;
