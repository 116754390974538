import React from "react";
import styled from "@emotion/styled";
import { Box, ThemeProvider } from "theme-ui";
import ContentRowCopy from "./ContactRowCopy";
import ContentRowLayout from "components/Global/ContentRowLayout";
import MediaCarousel from "components/MediaCarousel";
import ContactRowForm from "./ContactRowForm";

const ContactRow = ({ data }) => {
  const { label } = data;

  return (
    <ContentRowLayout label={label}>
      <ContentRowCopy data={data} />
      <ContactRowForm data={data} />
    </ContentRowLayout>
  );
};
export default ContactRow;
