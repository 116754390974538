import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const CopyRow = ({ data }) => {
  const { heading, body } = data || {};

  return (
    <Wrapper>
      <Heading as="h1" variant="heading" mb={[4, 5]}>
        {heading}
      </Heading>
      <Body variant="body">{documentToReactComponents(body?.json)}</Body>
    </Wrapper>
  );
};
export default CopyRow;

const Wrapper = styled(Box)``;
const Heading = styled(Text)``;
const Body = styled(Text)`
  ${({ theme }) =>
    theme.mq({
      lineHeight: ["115%", "115%"],
      width: ["100%", "95%"],
    })};
  display: block;
  p {
    margin-top: 1.5rem;
  }
`;
