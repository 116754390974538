import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import TableItem from "./TableItem";
import { css } from "@emotion/react";

const TableRowTable = ({ style, tableItems }) => {
  const Wrapper = styled(Box)`
    ${({ theme }) => {
      switch (style) {
        case "list":
          return css`
            display: grid;
            grid-template-columns: 1fr;
          `;
        default:
          return css`
            display: grid;
            grid-template-columns: 1fr;
          `;
      }
    }}
  `;
  return (
    <Wrapper>
      {tableItems?.map((data, i) => (
        <TableItem style={style} data={data} key={`table-item-${i}`} />
      ))}
    </Wrapper>
  );
};
export default TableRowTable;
