import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import { css } from "@emotion/react";
import reactStringReplace from "react-string-replace";

const TableItem = ({ style, data }) => {
  let [heading, body] = data;

  let defaultGridTemplate = "1fr 1fr";
  if (!body) defaultGridTemplate = "1fr";

  const Wrapper = styled(Box)`
    ${({ theme }) => {
      switch (style) {
        case "data":
          return css`
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            border-top: 1px solid ${theme.colors.grey[4]};
            &:last-of-type {
              border-bottom: 1px solid ${theme.colors.grey[4]};
            }
          `;
        default:
          return css`
            display: grid;
            grid-template-columns: ${defaultGridTemplate};
            border-top: 1px solid ${theme.colors.grey[4]};
            &:last-of-type {
              border-bottom: 1px solid ${theme.colors.grey[4]};
            }
          `;
      }
    }}
  `;
  const Heading = styled(Text)`
    ${() => {
      switch (style) {
        case "list":
          return css``;
        case "data":
          return css``;
      }
    }}
    h1 {
      margin: 0;
    }
    display: flex;
  `;
  const Body = styled(Text)`
    color: ${({ theme }) => theme.colors.grey[6]};
  `;
  let HeadingTextVariant = "tableDefault";
  let BodyTextVariant = "tableDefault";
  switch (style) {
    case "list":
      HeadingTextVariant = "tableDefault";
      BodyTextVariant = "tableDefault";
      break;
    case "data":
      HeadingTextVariant = "tableDataHeading";
      BodyTextVariant = "smallCap";
      break;
  }

  const iconRegex = /\[icon=.*?\]/g;
  const iconValueRegex = /\[icon=(.*?)\]/;

  if (heading.match(iconRegex)) {
    const val = heading.match(iconValueRegex)[1];

    let a = reactStringReplace(heading, iconRegex, (match, i) => (
      <>
        <Icon mr={["8px", "14px"]} key={`icon-${i}`}>
          {val}
        </Icon>{" "}
        {match}
      </>
    ));
    heading = a;
  }

  return (
    <Wrapper py={[2]}>
      <Heading variant={HeadingTextVariant} as="h1">
        {heading}
      </Heading>
      <Body as="p" variant={BodyTextVariant}>
        {body}
      </Body>
    </Wrapper>
  );
};
export default TableItem;

const Icon = styled(Box)`
  background-color: ${({ theme }) => theme.colors.grey[1]};
  display: flex;
  color: white;
  width: 30px;
  height: 30px;

  ${({ theme }) =>
    theme.mq({
      width: ["20px", "30px"],
      height: ["20px", "30px"],
    })};

  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;
