import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import Link from "next/link";
import slugify from "slugify";

const PageLinkRowListItem = ({ data }) => {
  const {
    title,
    linkPage,
    linkUrl,
    details: { tableData },
  } = data;

  const tableItems = tableData?.flat() || [];

  let hrefLink = linkUrl;
  if (linkPage) {
    hrefLink = `/${linkPage?.slug}`;

    if (linkPage?.parent) {
      hrefLink = `/${linkPage?.parent?.slug}-${linkPage?.slug}`;
      if (linkPage?.site !== "Marquette") {
        hrefLink = `/${String(slugify(linkPage?.site)).toLowerCase()}/${
          linkPage?.parent?.slug
        }-${linkPage?.slug}`;
      }
    }
  }

  return (
    <Link href={hrefLink || "/"} passHref>
      <Wrapper as="a" py={[2, 3]}>
        {tableItems?.map((data, i) => (
          <TextItem variant="pageLinkBody" key={`table-item-${i}`}>
            {data}
          </TextItem>
        ))}
      </Wrapper>
    </Link>
  );
};
export default PageLinkRowListItem;

const Wrapper = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[4]};

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[4]};
  }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const TextItem = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[6]};
  &:first-of-type {
    color: ${({ theme }) => theme.colors.text};
  }
`;
