import React from "react";

const SvgDownloadIcon = () => {
  return (
    <svg
      width="40"
      height="51"
      viewBox="0 0 40 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 14L21 13L19 13L19 14L21 14ZM19.2929 31.7071C19.6834 32.0976 20.3166 32.0976 20.7071 31.7071L27.0711 25.3431C27.4616 24.9526 27.4616 24.3195 27.0711 23.9289C26.6805 23.5384 26.0474 23.5384 25.6569 23.9289L20 29.5858L14.3431 23.9289C13.9526 23.5384 13.3195 23.5384 12.9289 23.9289C12.5384 24.3195 12.5384 24.9526 12.9289 25.3431L19.2929 31.7071ZM39 10.3793H40V9.997L39.7449 9.71219L39 10.3793ZM39 50V51H40V50H39ZM1 50H0V51H1V50ZM1 1V0H0V1H1ZM30.6 1L31.3449 0.332854L31.0468 0H30.6V1ZM12 36H11V38H12V36ZM28 38H29V36H28V38ZM19 14L19 31L21 31L21 14L19 14ZM38 10.3793V50H40V10.3793H38ZM39 49H1V51H39V49ZM2 50V1H0V50H2ZM1 2H30.6V0H1V2ZM29.8551 1.66715L38.2551 11.0465L39.7449 9.71219L31.3449 0.332854L29.8551 1.66715ZM12 38H28V36H12V38Z"
        fill="#222222"
      />
    </svg>
  );
};

export default SvgDownloadIcon;
