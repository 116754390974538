import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import ContentRowLayout from "components/Global/ContentRowLayout";
import StackRowItem from "./StackRowItem";

const StackRow = ({ data }) => {
  const stackRowItems = data?.stackRowItemsCollection?.items;

  const { label } = data;

  return (
    <ContentRowLayout
      fullWidth
      label={label}
      noBorder
      borderType="over"
      sx={{ ".content-row-layout-grid": { pb: [0, 2] } }}
    >
      {stackRowItems?.map((data, i) => (
        <StackRowItem data={data} key={`stack-row-item-${i}`} />
      ))}
    </ContentRowLayout>
  );
};
export default StackRow;
