import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";

const ThankYouMessage = () => {
  return (
    <Wrapper p={[4, 5]}>
      <Text variant="formLabel">
        Thank you! Your submission has been received!
      </Text>
    </Wrapper>
  );
};
export default ThankYouMessage;

const Wrapper = styled(Box)`
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
