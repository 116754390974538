import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import Arrow from "components/Arrow";
import Link from "next/link";

const GalleryRowCopy = ({ data }) => {
  const { heading, linkUrl, linkPage, linkText } = data;

  let hrefLink = linkUrl;
  if (linkPage) {
    hrefLink = `/${linkPage?.slug}`;
    if (linkPage?.parent) {
      hrefLink = `/${linkPage?.parent?.slug}-${linkPage?.slug}`;
    }
  }

  return (
    <Wrapper>
      <Left>
        <Text variant="copyHeading">{heading}</Text>
      </Left>
      <Right>
        {hrefLink && linkText && (
          <Link href={hrefLink || "/"} passHref>
            <LinkWrapper as="a">
              <Text variant="smallCap">{linkText}</Text>
              <ArrowWrapper ml={[2]}>
                <Arrow />
              </ArrowWrapper>
            </LinkWrapper>
          </Link>
        )}
      </Right>
    </Wrapper>
  );
};
export default GalleryRowCopy;

const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
const Side = styled(Box)``;
const Left = styled(Side)`
  /* width: 50%; */
`;
const Right = styled(Side)``;

const LinkWrapper = styled(Box)`
  * {
    color: ${({ theme }) => theme.colors.text} !important;
  }

  display: flex;
  align-items: center;

  svg {
    ${({ theme }) => theme.transitions.arrowShift};
  }

  &:hover {
    svg {
      transform: translateX(8px);
    }
  }
`;

const ArrowWrapper = styled(Box)`
  ${({ theme }) =>
    theme.mq({
      width: ["8px", "12px"],
    })};
`;
