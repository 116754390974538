import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import Copy from "components/Copy";

const ContactRowCopy = ({ data }) => {
  const { copy } = data;

  return (
    <Wrapper>
      <Copy data={copy.json} />
    </Wrapper>
  );
};
export default ContactRowCopy;

const Wrapper = styled(Box)`
  color: ${({ theme }) => theme.colors.text};

  width: 95%;
  ${({ theme }) => theme.mediaQueries.medium} {
    max-width: ${({ theme }) => theme.maxWidth.copy};
  }
`;
