import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";

const HeroRowCopy = () => {
  return (
    <Wrapper>
      <Text as="h1" variant="copyHeading">
        2021 — Year in Review
      </Text>
      <Text as="p" variant="copyBody" mt={[2, 3]}>
        Updates on new acquisitions , funds management overview, distributions
        and market expectations ahead.
      </Text>
    </Wrapper>
  );
};
export default HeroRowCopy;

const Wrapper = styled(Box)`
  max-width: ${({ theme }) => theme.maxWidth.copy};
`;
