import React from "react";
import styled from "@emotion/styled";
import { Box, ThemeProvider } from "theme-ui";
import ContentRowCopy from "./ContentRowCopy";
import ContentRowLayout from "components/Global/ContentRowLayout";
import MediaCarousel from "components/MediaCarousel";

const ContentRow = ({ data }) => {
  const { label, theme, mediaCollection, video, videoThumbnail } = data;

  return (
    <ContentRowLayout theme={theme} label={label}>
      <ContentRowCopy data={data} />
      <MediaCarousel collection={mediaCollection} video={video} videoThumbnail={videoThumbnail} />
    </ContentRowLayout>
  );
};
export default ContentRow;
