import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import PageLinkRowRowItem from "./PageLinkRowRowItem";

const PageLinkRowRow = ({ data, setHoverImage }) => {
  const pageLinkItems = data?.pageLinkItemsCollection?.items;

  return (
    <Wrapper>
      {pageLinkItems?.map((data, i) => (
        <PageLinkRowRowItem
          setHoverImage={setHoverImage}
          data={data}
          key={`PageLinkRowRowItem-${i}`}
        />
      ))}
    </Wrapper>
  );
};
export default PageLinkRowRow;

const Wrapper = styled(Box)``;
