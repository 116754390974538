import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import PageLinkRowRowItem from "../PageLinkRowRow/PageLinkRowRowItem";
import PageLinkRowListItem from "./PageLinkRowListItem";
import ContentRowLayout from "components/Global/ContentRowLayout";
import MediaCarousel from "components/MediaCarousel";

const PageLinkRowList = ({ data }) => {
  const pageLinkItems = data?.pageLinkItemsCollection?.items;
  const { mediaCollection } = data;

  return (
    <Wrapper>
      <Box pr={[0, 0, 4]} mb={[4, 4, 0]}>
        {pageLinkItems?.map((data, i) => (
          <PageLinkRowListItem data={data} key={`PageLinkRowList-${i}`} />
        ))}
      </Box>
      <MediaCarousel collection={mediaCollection} />
    </Wrapper>
  );
};
export default PageLinkRowList;

const Wrapper = styled(Box)`
  display: grid;

  ${({ theme }) => theme.mediaQueries.medium} {
    grid-template-columns: 1fr 1fr;
  }
`;
