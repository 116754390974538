import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import AccordionItem from "./AccordionItem";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const AccordionRowComponent = ({ data }) => {
  const { heading, accordionItemCollection, style } = data || {};

  const accordionItems = accordionItemCollection?.items;

  return (
    <Wrapper>
      {accordionItems?.map((data, i) => (
        <AccordionItem
          data={data}
          style={String(style).toLowerCase()}
          key={`accordion-item-${i}`}
        />
      ))}
    </Wrapper>
  );
};
export default AccordionRowComponent;

const Wrapper = styled(Box)``;
const Heading = styled(Text)``;
const HeadingWrapper = styled(Box)``;
