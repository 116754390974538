import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import GalleryRowCarouselItem from "./GalleryRowCarouseltem";
import GalleryRowCarouselProgressBar from "./GalleryRowCarouselProgressBar";

import { FreeMode, Mousewheel } from "swiper";
import { useBreakpointIndex } from "@theme-ui/match-media";

const GalleryRowCarousel = ({ data }) => {
  const carouselImages = data?.carouselCollection?.items;

  const [progressSettings, setProgressSettings] = useState({
    progress: 0,
    width: 0,
    virtualSize: 0,
  });

  const [showProgress, setShowProgress] = useState(true);

  const [carouselHeight, setCarouselHeight] = useState(null);

  const handleSliderMove = ({ progress, width, virtualSize }) => {
    setProgressSettings({ progress, width, virtualSize });
  };

  const handleHeightChange = (swiper) => {
    const { height, width, virtualSize, progress } = swiper;
    setCarouselHeight(height);
    handleSliderMove(swiper);
    if (width === virtualSize) {
      if (showProgress) setShowProgress(false);
    } else {
      if (!showProgress) setShowProgress(true);
    }
  };

  const wrapperRef = useRef();

  useEffect(() => {
    if (wrapperRef?.current) {
      setCarouselHeight(wrapperRef?.current?.getBoundingClientRect().height);
    }
  }, [wrapperRef]);

  const breakpoint = useBreakpointIndex();

  return (
    <Container mt={[3, 4]}>
      <Wrapper carouselHeight={carouselHeight} ref={wrapperRef}>
        {Boolean(carouselHeight) && (
          <Swiper
            slidesPerView={"auto"}
            mousewheel={true}
            freeMode={true}
            modules={[FreeMode, Mousewheel]}
            spaceBetween={breakpoint <= 1 ? 16 : 30}
            onSliderMove={handleSliderMove}
            onScroll={handleSliderMove}
            onInit={(e) => {
              handleSliderMove(e);
            }}
            onResize={handleHeightChange}
          >
            {carouselImages?.map(({ url, height, width }, i) => {
              const [classLandscape, classPortrait] = [
                "gallery-slider-landscape",
                "gallery-slider-portrait",
              ];
              const classOrientation =
                height > width ? classPortrait : classLandscape;
              return (
                <SwiperSlide
                  className={classOrientation}
                  key={`carousle-image-${i}`}
                >
                  <GalleryRowCarouselItem src={url || "/"} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </Wrapper>
      {showProgress && (
        <GalleryRowCarouselProgressBar progressSettings={progressSettings} />
      )}
    </Container>
  );
};
export default GalleryRowCarousel;

const Wrapper = styled(Box)`
  &,
  .swiper {
    border-radius: ${({ theme }) => theme.radii.global};
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
  .gallery-slider-landscape {
    height: 100%;
    width: ${({ carouselHeight }) => carouselHeight * (4 / 3)}px;
  }
  .gallery-slider-portrait {
    height: 100%;
    width: ${({ carouselHeight }) => carouselHeight * (2 / 3)}px;
  }

  flex: 1;
`;

const Container = styled(Box)`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
