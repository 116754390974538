import React from "react";
import { Box } from "theme-ui";

const SvgMinusIcon = () => {
  return (
    <svg
      width="13"
      height="2"
      viewBox="0 0 13 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.0654297" width="13" height="1" fill="white" />
    </svg>
  );
};

export default SvgMinusIcon;
