import React, { useState } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import Input from "components/FormFields/Input";
import { Box, Button, Text } from "theme-ui";
import axios from "axios";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import TextArea from "components/FormFields/TextArea";
import Switch from "components/FormFields/Switch";
import * as Yup from "yup";
import ErrorMessage from "components/FormFields/ErrorMessage";
import ThankYouMessage from "components/FormFields/ThankYouMessage";
import PhoneField from "components/FormFields/PhoneField";

const FormContact = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const phoneRegExp =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return (
    <Wrapper p={["21px", "41px"]}>
      {isSubmitted ? (
        <ThankYouMessage />
      ) : (
        <Formik
          initialValues={{
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            company: "",
            message: "",
            SubscribedSMS: "",
            SubscribedEmail: "",
            ExistingInvestor: "",
            source: "Website - Contact Form",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required("Email is required.")
              .email("Invalid email."),
            firstname: Yup.string().required("First Name is required."),
            lastname: Yup.string().required("Last Name is required."),
            phone: Yup.string()
              .matches(phoneRegExp, "Invalid phone number.")
              .required("Phone is required."),
            ExistingInvestor: Yup.string().required("This field is required."),
          })}
          onSubmit={async (val, actions) => {
            const values = { ...val };

            values.SubscribedEmail = values.SubscribedEmail === "yes";
            values.SubscribedSMS = values.SubscribedSMS === "yes";
            values.ExistingInvestor = values.ExistingInvestor === "yes";

            try {
              actions.setSubmitting(true);
              await axios.post("/api/customerIo", values);
              actions.setSubmitting(false);
              setIsSubmitted(true);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <StyledForm fade={String(isSubmitting)}>
              <Text variant="formHeading" mb={["22px", "45px"]} as="h1">
                Enquries
              </Text>
              <Input name="firstname" placeholder="First Name*" />
              <Input name="lastname" placeholder="Last Name*" />
              <Input name="email" placeholder="Email*" />
              <PhoneField
                helperText="Please use international number"
                name="phone"
                placeholder="Phone Number (+61...)"
              />
              <Input name="company" placeholder="Company" />
              <TextArea name="message" />
              <Box>
                <Switch
                  name="ExistingInvestor"
                  label={"Are you an existing Marquette investor?*"}
                />
                {errors.ExistingInvestor && touched.ExistingInvestor && (
                  <ErrorMessage message={errors.ExistingInvestor} />
                )}
              </Box>

              <Button type="submit" variant="pill" mt={["18px", "18px"]}>
                {isSubmitting ? `Submitting...` : `Submit`}
              </Button>
            </StyledForm>
          )}
        </Formik>
      )}
    </Wrapper>
  );
};

export default FormContact;

const Wrapper = styled(Box)`
  background-color: white;
`;
const StyledForm = styled(FormikForm)`
  min-height: 40vh;
  display: block;

  ${({ fade }) =>
    fade === "true"
      ? css`
          opacity: 0.3;
          pointer-events: none;
        `
      : ``};
`;
