import React from "react";

const SvgPlusIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 24H13L13 13H24V11H13L13 0H11L11 11H0V13H11L11 24Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgPlusIcon;
