import React, { useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { AppContext } from "contexts/AppContext";

import HeroRow from "components/Rows/HeroRow";
import TableRow from "components/Rows/TableRow";
import AccordionRow from "components/Rows/AccordionRow";
import CopyRow from "components/Rows/CopyRow";
import PageLinkRow from "components/Rows/PageLinkRow";
import DownloadRow from "components/Rows/DownloadRow";
import GalleryRow from "components/Rows/GalleryRow";
import ContentRow from "components/Rows/ContentRow";
import StackRow from "components/Rows/StackRow";
import ImageRow from "components/Rows/ImageRow";
import ContactRow from "components/Rows/ContactRow";
import SpotifyRow from "components/Rows/SpotifyRow";

const ContentRows = ({ rowComponents }) => {
  const { setCurrentLastRow, showHeroSlide } = useContext(AppContext);

  useEffect(() => {
    if (rowComponents) {
      const lastRow = rowComponents[rowComponents?.length - 1];
      setCurrentLastRow(lastRow?.__typename);
    }
  }, [rowComponents]);

  return (
    <ContentRowsWrapper mt={showHeroSlide ? 0 : [3, 4]}>
      {rowComponents?.map((rowData, i) => {
        const { __typename } = rowData;
        let RowComponent;
        const isLast = parseInt(rowComponents.length - 1) === i;
        rowData = { ...rowData, isLast };

        switch (__typename) {
          case "ContentRow":
            RowComponent = <ContentRow data={rowData} />;
            break;
          case "HeroRow":
            RowComponent = <HeroRow data={rowData} />;
            break;
          case "TableRow":
            RowComponent = <TableRow data={rowData} />;
            break;
          case "AccordionRow":
            RowComponent = <AccordionRow data={rowData} />;
            break;
          case "PageLinkRow":
            RowComponent = <PageLinkRow data={rowData} />;
            break;
          case "CopyRow":
            RowComponent = <CopyRow data={rowData} />;
            break;
          case "DownloadRow":
            RowComponent = <DownloadRow data={rowData} />;
            break;
          case "GalleryRow":
            RowComponent = <GalleryRow data={rowData} />;
            break;
          case "StackRow":
            RowComponent = <StackRow data={rowData} />;
            break;
          case "ImageRow":
            RowComponent = <ImageRow data={rowData} />;
            break;
          case "ContactRow":
            RowComponent = <ContactRow data={rowData} />;
            break;
          case "SpotifyRow":
            RowComponent = <SpotifyRow data={rowData} />;
            break;
        }
        return <Row key={`content-row-${i}`}>{RowComponent}</Row>;
      })}
    </ContentRowsWrapper>
  );
};
export default ContentRows;

const ContentRowsWrapper = styled(Box)``;
const Row = styled(Box)`
  z-index: 2;
  position: relative;
`;
