import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import HeroRowMedia from "./HeroRowMedia";
import HeroRowCopy from "./HeroRowCopy";
import ContentRowLayout from "components/Global/ContentRowLayout";

const HeroRow = ({ data }) => {
  const { label } = data;

  return (
    <ContentRowLayout label={label}>
      <HeroRowCopy />
      <HeroRowMedia data={data} />
    </ContentRowLayout>
  );
};
export default HeroRow;
