import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import ContentRowLayoutComponent from "components/Global/ContentRowLayout";
import GalleryRowCopy from "./GalleryRowCopy";
import GalleryRowCarousel from "./GalleryRowCarousel";

const GalleryRow = ({ data }) => {
  const { label } = data;

  return (
    <ContentRowLayout
      label={label}
      noBorder
      fullWidth
      sx={{ ".content-row-layout-container": { pt: [2, 3] } }}
    >
      <GalleryRowCopy data={data} />
      <GalleryRowCarousel data={data} />
    </ContentRowLayout>
  );
};
export default GalleryRow;

const ContentRowLayout = styled(ContentRowLayoutComponent)`
  .content-row-layout-grid {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 80vh;
    max-height: 900px;
    min-height: 550px;
  }
  .content-row-layout-container {
    height: 100%;
  }
  .content-row-layout-wrapper {
    height: 100%;
  }

  background-color: ${({ theme }) =>
    theme.colors.rows.galleryRow.bg} !important;
`;
