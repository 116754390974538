import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import Image from "next/image";

const GalleryRowCarouselItem = ({ type = "landscape", src }) => {
  return (
    <ImageWrapper>
      <Image
        src={`${src}` || "/"}
        layout="fill"
        objectFit="cover"
        alt="Gallery Media"
      />
    </ImageWrapper>
  );
};
export default GalleryRowCarouselItem;

const ImageWrapper = styled(Box)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.imagePlaceholderBg};
  &,
  img {
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.radii.global};
    /* position: absolute;
    object-fit: cover; */
  }
`;
