import React, { useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Container } from "theme-ui";
import Overview from "components/Overview";
import { useRouter } from "next/router";

const PageOverview = ({ copy, isHome, ...rest }) => {
  const router = useRouter();

  if (isHome || !copy) return null;

  return (
    <Wrapper {...rest}>
      <Container px={[3, 4]}>
        <Overview mt={[3, 4]} pb={[3, 4]} data={{ body: copy }} />
      </Container>
    </Wrapper>
  );
};
export default PageOverview;

const Wrapper = styled(Box)``;
