import React from "react";
import ContentRowLayout from "components/Global/ContentRowLayout";
import TableRowCopy from "./TableRowCopy";
import TableRowMedia from "./TableRowMedia";
import MediaCarousel from "components/MediaCarousel";
import { useRouter } from "next/router";
import Media from "components/Media";
import styled from "@emotion/styled";
import { AspectRatio, Box } from "theme-ui";

const TableRow = ({ data }) => {
  const { imageCollection, label, imageLink, video } = data;

  const [firstImage] = imageCollection?.items;
  const isImageExist = firstImage?.url;
  const router = useRouter();

  let borderType = "under";

  if (!isImageExist) {
    borderType = "over";
  }

  const mediaClick = () => {
    if (imageLink) router.push(imageLink);
  };

  return (
    <ContentRowLayout
      label={label}
      fullWidth={!isImageExist}
      noBorder={!isImageExist}
      borderType={borderType}
    >
      <TableRowCopy data={data} isImageExist={isImageExist} />
      <MediaCarousel
        collection={imageCollection}
        video={video}
        autoPlay={true}
        onClick={mediaClick}
        sx={{ cursor: imageLink ? "pointer" : "initial" }}
      />
    </ContentRowLayout>
  );
};
export default TableRow;

const VideoWrapper = styled(AspectRatio)`
  background-color: red;
  position: relative;
`;
