import React from "react";
import styled from "@emotion/styled";
import { AspectRatio, Box, Container } from "theme-ui";
import Image from "next/image";
import { useBreakpointIndex } from "@theme-ui/match-media";

const ImageRow = ({ data }) => {
  const { image, isLast } = data;

  let imageRatio = 1374 / 536;

  const index = useBreakpointIndex();

  if (index > 1) {
    imageRatio = 1374 / 536;
  } else {
    imageRatio = 3 / 2;
  }

  return (
    <Container px={[3, 4]} mb={isLast ? 0 : ["48px", "90px"]}>
      <AspectRatio ratio={imageRatio}>
        <ImageWrapper>
          <Image
            alt="Image Row"
            src={image?.url || "/"}
            layout="fill"
            objectFit="cover"
          />
        </ImageWrapper>
      </AspectRatio>
    </Container>
  );
};
export default ImageRow;

const ImageWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.imagePlaceholderBg};

  &,
  img {
    border-radius: ${({ theme }) => theme.radii.global};
  }
`;
