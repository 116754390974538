import React from "react";
import styled from "@emotion/styled";
import { AspectRatio, Box } from "theme-ui";
import { SwiperSlide, Swiper } from "swiper/react";
import Media from "components/Media";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import SvgHeroControllerRight from "components/Svg/HeroController/SvgHeroControllerRight";

const HeroRowMedia = ({ data }) => {
  const { mediaCollection } = data || {};
  const heroSlides = mediaCollection?.items;

  return (
    <Wrapper ratio={16 / 9}>
      <InnerWrapper>
        <Swiper modules={[EffectFade]} effect="fade">
          {heroSlides?.map(({ url, contentType }, i) => (
            <SwiperSlide key={`hero-slide-${i}`}>
              <Media
                type={
                  contentType === "video/mp4" ||
                  contentType === "application/x-mpegURL"
                    ? "video"
                    : "image"
                }
                src={url}
                alt="Hero Row Media"
                layout="fill"
                videoSettings={{
                  autoPlay: true,
                  loop: true,
                  muted: true,
                  controls: false,
                  playsInline: true,
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </InnerWrapper>
    </Wrapper>
  );
};
export default HeroRowMedia;

const Wrapper = styled(AspectRatio)`
  .swiper {
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.radii.global};
  }
  .swiper-slide-visible > div {
    display: block;
  }
  border-radius: ${({ theme }) => theme.radii.global};
  background-color: ${({ theme }) => theme.colors.grey[3]};
`;

const InnerWrapper = styled(Box)`
  width: 100%;
  height: 100%;
`;
