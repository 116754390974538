import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import ContentRowLayout from "components/Global/ContentRowLayout";
import ContentRowCopy from "../ContentRow/ContentRowCopy";
import SpotifyEmbed from "components/SpotifyEmbed";

const SpotifyRow = ({ data }) => {
  const { label, spotifySourceUrl } = data;

  return (
    <ContentRowLayout label={label}>
      <Box mt={[3, 0]}>
        <ContentRowCopy data={data} />
      </Box>
      <SpotifyEmbed src={spotifySourceUrl} />
    </ContentRowLayout>
  );
};
export default SpotifyRow;

const Wrapper = styled(Box)``;
